// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAIvbN5-YHc-RyAVFcBeQ7GyYTetLRB3HY",
    authDomain: "fruitofwar-6b0e0.firebaseapp.com",
    projectId: "fruitofwar-6b0e0",
    storageBucket: "fruitofwar-6b0e0.appspot.com",
    messagingSenderId: "186721774279",
    appId: "1:186721774279:web:ae54136cd92d83fb27ace2",
    measurementId: "G-RLQL3M1P11"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
